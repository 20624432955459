<template>
  <div>
    <div class="map-body">
      <ul>
        <li>
          <div class="li-table1">
            <div>
              <div>
                <span>充值规则</span>
                <span style="margin-left: 133px;">
                  <el-radio-group v-model="guiz">
                    <el-radio label="1" value="1">启用</el-radio>
                    <el-radio label="0" value="0">停用</el-radio>
                  </el-radio-group>
                </span>
              </div>
              <div class="table-top">
                <el-form :model="guize" ref="formTable">
                  <el-table :data="guize.tableData" border max-height="300" @selection-change="handleSelectionChange" ref="tableData">
                    <el-table-column
                      type="selection"
                      width="40">
                    </el-table-column>
                    <el-table-column label="方案" align="center">
                      <template slot-scope="scope">
                        <el-form-item :prop="'tableData.'+scope.$index+'.plan_name'" :rules="{validator: validatorPlan_name, prodObj: scope.row, trigger: 'blur'}">
                          <el-input v-model="scope.row.plan_name" placeholder="请输入"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="充值金额" align='center'>
                      <template slot-scope="scope">
                        <el-form-item :prop="'tableData.'+scope.$index+'.amt_topup'" :rules="{validator: validatorAmt_topup, prodObj: scope.row, trigger: 'blur'}">
                          <el-input v-model="scope.row.amt_topup" placeholder="请输入"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="赠送金额" align='center'>
                      <template slot-scope="scope">
                        <el-form-item :prop="'tableData.'+scope.$index+'.amt_give'" :rules="{validator: validatorAmt_give, prodObj: scope.row, trigger: 'blur'}">
                          <el-input v-model="scope.row.amt_give" placeholder="请输入"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
              </div>
              <div class="btn-table">
                <el-button @click="addRow">增加一行+</el-button>
                <el-button @click="delRow">删除一行-</el-button>
              </div>
            </div>
          </div>
        </li>
        <div style="height: 20px;"></div>
        <li>
          <div class="li-list">
            <div style="padding-left: 20px;">
              <span>使用规则</span>
              <span style="margin-left: 133px;">
                <el-radio-group v-model="useGui">
                  <el-radio label="1" value="1">启用</el-radio>
                  <el-radio label="0" value="0">停用</el-radio>
                </el-radio-group>
              </span>
            </div>
            <div class="jine">
              使用充值金额时：单次不超过整单的 <el-input class="inputi" v-model="inputt"></el-input> %
            </div>
          </div>
        </li>
        <div style="height: 19px;"></div>
        <li>
          <div class="li-table1">
            <div>
              <div>
                <span>会员等级</span>
                <span style="margin-left: 133px;">
                  <el-radio-group v-model="vipLeavel">
                    <el-radio label="1" value="1">启用</el-radio>
                    <el-radio label="0" value="0">停用</el-radio>
                  </el-radio-group>
                </span>
                <div style="margin-left: 238px;padding-top: 30px;">
                  <el-radio-group v-model="vipLeavelK">
                    <el-radio label="1" value="1">首次充值确定会员等级</el-radio>
                    <el-radio label="2" value="2">累计充值确定会员等级</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="table-top">
                <el-form :model="guize" ref="formTable">
                <el-table :data="guize.tableDataa" border max-height="300" @selection-change="handleSelectionChangeg" ref="tableDataa">
                  <el-table-column
                    type="selection"
                    width="40">
                  </el-table-column>
                  <el-table-column label="会员等级" align="center">
                    <template slot-scope="scope">
                      <el-form-item :prop="'tableDataa.'+scope.$index+'.level_name'" :rules="{validator: validatorLevel_name, prodObj: scope.row, trigger: 'blur'}">
                        <el-input v-model="scope.row.level_name" placeholder="请输入"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="结账折扣（%）" align="center">
                    <template slot-scope="scope">
                      <el-form-item :prop="'tableDataa.'+scope.$index+'.discount'" :rules="{validator: validatorDiscount, prodObj: scope.row, trigger: 'blur'}">
                        <el-input v-model="scope.row.discount" placeholder="请输入"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="充值区间（元）"  align="center">
                    <el-table-column label="最低充值数" align="center">
                      <template slot-scope="scope">
                        <el-form-item :prop="'tableDataa.'+scope.$index+'.bgn_topup'" :rules="{validator: validatorDgn_topup, prodObj: scope.row, trigger: 'blur'}">
                          <el-input v-model="scope.row.bgn_topup" placeholder="请输入"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="最高充值数" align="center">
                      <template slot-scope="scope">
                        <el-form-item :prop="'tableDataa.'+scope.$index+'.end_topup'" :rules="{validator: validatorEnd_topup, prodObj: scope.row, trigger: 'blur'}">
                          <el-input v-model="scope.row.end_topup" placeholder="请输入"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="有效期" align="center">
                    <template slot-scope="scope" style="display: flex;">
                      <el-form-item :prop="'tableDataa.'+scope.$index+'.end_topup'" :rules="{validator: validatorValid_term, prodObj: scope.row, trigger: 'blur'}">
                        <el-input type="text" v-model="scope.row.valid_term" style="width: 30px;text-align: center;border: none;border-bottom: 1px solid #000;"></el-input>个月
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
                </el-form>
              </div>
              <div class="btn-table">
                <el-button @click="addRoww">增加一行+</el-button>
                <el-button @click="delRoww">删除一行-</el-button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="btn-op">
        <el-button @click="saveRule">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { vipRule, insertRule } from '@/api/vip.js'
export default {
  data () {
    return {
      guiz: '0',
      useGui: '0',
      vipLeavel: '0',
      vipLeavelK: '1',
      multipleSelection: null,
      multipleSelectionn: null,
      inputt: null,
      guize: {
        tableData: [],
        tableDataa: [],
      },
      tableIndex: null,
      tableIndexx: null
    }
  },
  created() {
    this.vipRule()
  },
  methods: {
    async vipRule() {
      try{
        let param = {
          data: {ent_id: this.$ent_id()}
        }
        const res = await vipRule(param)
        if(res.data.code === '200') {
          console.log(JSON.parse(res.data.body.data))
          this.guiz = JSON.parse(res.data.body.data).flag_topup
          this.useGui = JSON.parse(res.data.body.data).flag_use
          this.vipLeavel = JSON.parse(res.data.body.data).flag_mc_level
          this.vipLeavelK = JSON.parse(res.data.body.data).rule_level_type
          let tableDataa =  JSON.parse(res.data.body.data).list_level
          let tableData =  JSON.parse(res.data.body.data).list_topup
          tableDataa.map((item, index)=> item.level_id = index + 1 + '')
          tableData.map((item, index)=> item.level_id = index + 1 + '')
          this.guize.tableDataa = []
          this.guize.tableData = []
          this.guize.tableDataa = tableDataa
          this.guize.tableData = tableData
          this.inputt = JSON.parse(res.data.body.data).rule_use.rate_limit
          this.$refs.formTable.resetFields()
        }
      }
      catch{}
    },
    insertRule(formName) {
      let param = {
        data: {
          ent_id: this.$ent_id(),
          flag_topup: this.guiz,
          flag_use: this.useGui,
          flag_mc_level: this.vipLeavel,
          list_topup: this.guize.tableData,
          rate_limit: this.inputt,
          list_level: this.guize.tableDataa,
          rule_level_type: this.vipLeavelK
        }
      }
      this.$refs.formTable.validate((valid) => {
        if(valid) {
          insertRule(param)
          .then((res) => {
            if(res.data.code === '200') {
              this.$message.success('保存成功')
            }
          })
        } else {
          this.$message.error('表格里的输入不符合规则请检查')
        }
      })
    },
     validatorDiscount(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } if(0 > value >= 100) {
        return callback(new Error('此次输入必须大于0并且小于等于100'))
      } else {
        callback()
      }
    },
    validatorAmt_give(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } if(value < 0) {
        return callback(new Error('此次输入必须大于等于0'))
      } else {
        callback()
      }
    },
    validatorValid_term(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } if(value < 0) {
        return callback(new Error('此次输入必须大于0'))
      } else {
        callback()
      }
    },
    validatorAmt_topup(rule, value, callback) {
      if(value <= '0') {
        return callback(new Error('此次输入必须大于0'))
      } else if(!value) {
        return callback(new Error('此项为必填项'))
      } else {
        callback()
      }
    },
    validatorPlan_name(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } else {
        callback()
      }
    },
    addRow () {
      if(this.guize.tableData.length == 0){
        this.guize.tableData.push({
          level_id: '1',
          plan_name: null,
          amt_topup: null,
          amt_give: null
        })
      } else {
        let level_id = Number(this.guize.tableData[this.guize.tableData.length - 1].level_id) + 1 + ''
        this.guize.tableData.push({
          level_id,
          plan_name: null,
          amt_topup: null,
          amt_give: null
        })
      }
      console.log(this.guize.tableData)
    },
    validatorDgn_topup(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } if(value < 0) {
        return callback(new Error('此次输入必须大于等于0'))
      } else {
        callback()
      }
    },
    delRow () {
      if(this.tableIndex === null) {
        this.$message.info('必须选择一项再进行删除操作,且只能选择一个')
        return false
      } else {
          this.guize.tableData.splice(this.tableIndex, 1)
          this.$message.success('删除成功')
          this.tableIndex = null
          this.guize.tableData.map((item, index) => {
            item.level_id = index + 1
          })
      }
      
    },
    addRoww () {
      if(this.guize.tableDataa.length == 0) {
        this.guize.tableDataa.push({
          level_id: 1,
          level_name: null,
          bgn_topup: null,
          end_topup: null,
          valid_term: null,
          discount: null
        })
      } else {
        let level_id = Number(this.guize.tableDataa[this.guize.tableDataa.length-1].level_id) + 1 + ''
        this.guize.tableDataa.push({
          level_id,
          level_name: null,
          bgn_topup: null,
          end_topup: null,
          valid_term: null,
          discount: null
        })
        if(this.guize.tableDataa[this.guize.tableDataa.length - 2].end_topup) {
          this.guize.tableDataa[this.guize.tableDataa.length - 1].bgn_topup = this.guize.tableDataa[this.guize.tableDataa.length - 2].end_topup
        }
      }
    },
    validatorLevel_name(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } else {
        callback()
      }
    },
    validatorEnd_topup(rule, value, callback) {
      if(!value) {
        return callback(new Error('此项为必填项'))
      } if(value < 0) {
        return callback(new Error('此次输入必须大于0'))
      } else {
        callback()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
      val.forEach((val, index) => {
        this.guize.tableData.forEach((v, i) => {
          if(val.plan_name == v.plan_name) {
            this.tableIndex = i
          }
        })
      })
      console.log(this.tableIndex)
    },
    handleSelectionChangeg(val) {
      this.multipleSelectionn = val;
      console.log(this.multipleSelectionn)
      val.forEach((val, index) => {
        this.guize.tableDataa.forEach((v, i) => {
          if(val.level_name == v.level_name) {
            this.tableIndexx = i
          }
        })
      })
      console.log(this.tableIndexx)
    },
    zhe_kou(row, column, cell, event){
      // console.log(`这是`,row, column, cell, event)

    },
    delRoww () {
      if(this.tableIndexx === null) {
        this.$message.info('必须选择一项再进行删除操作,且只能选择一个')
        return false
      } else {
          this.guize.tableDataa.splice(this.tableIndexx, 1)
          this.$message.success('删除成功')
          this.tableIndexx = null
      }
    },
    saveRule() {
      this.insertRule()
    }
  }
}
</script>

<style scoped lang="scss">
.map-body{
  width: 95%;
  height: 1283px;
  background: #F9FBFE;
  display: flex;
  padding: 11px 22px 5px 20px;
  justify-content: center;
  flex-direction: column;
  ul{
    width: 100%;
    li{
      list-style: none;
    }
  }

}
.li-table1{
  height: 430px;
  background: #fff;
  padding: 31px 44px 23px 22px;
  overflow: auto;
}
.table-top{
  padding-top: 31px;
}
/deep/ .el-input__inner{
  border: none;
  text-align: center;
  padding-left: 0;
}
.btn-table{
  padding-top: 25px;
  display: flex;
  justify-content: center;
}
/deep/ .el-button{
  width: 140px;
  height: 44px;
  background: #9A86DB;
  border-radius: 10px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
}
.li-list{
  height: auto;
  padding: 31px 0px 28px 0px;
  background: #fff;
}
.jine  /deep/ .el-input__inner{
  width: 60px;
  height: 30px;
  border: 2px solid #CCCCCC;
  border-radius: 5px;
}
/deep/ .inputi{
  width: 60px;
}
.jine{
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-op{
  padding-top: 54px;
  display: flex;
  justify-content: center;
}
.trem /deep/ .el-input__inner{
  width: 30px;
  text-align: center;
}
</style>
